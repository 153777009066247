.containerClass {
    padding: var(--GW-SPACING-XL) var(--GW-SPACING-XL) var(--GW-SPACING-XXL) var(--GW-SPACING-XL);
    margin-bottom: var(--GW-SPACING-XL);
}

.containerHeadingClass {
 padding-bottom: var(--GW-SPACING-SM);
 border-bottom: 1px solid var(--GW-COLOR-BORDER);
}

.userActionButton {
    background: var(--GW-BUTTON-FILLED-BACKGROUND-COLOR) !important;
    opacity: 1 !important;
    
}
