:global(#modalAcceptWork) {
    overflow: visible;
}

:global(#modalDelayWork) {
    overflow: visible;
}

.serviceRequestDetailsTable hr{
    --gw-ve-service-request-details-hr-margin-bottom: 0.5rem;
    --gw-ve-service-request-details-hr-margin: 0;

    margin: var( --gw-ve-service-request-details-hr-margin);
    margin-bottom: var( --gw-ve-service-request-details-hr-margin-bottom);
}

.serviceRequestDetailsTable h3{
    --gw-ve-service-request-details-h3-margin-top: 1rem;

    margin-top: var( --gw-ve-service-request-details-h3-margin-top);
}

.serviceRequestDetailsTable p{
    margin-block-start: 0;
    margin-block-end: 0;
}

.serviceRequestsDetailsTitle{
    --gw-ve-service-request-details-title-padding-bottom-top: 2rem;
    padding: var(--gw-ve-service-request-details-title-padding-bottom-top) 0;
}

.serviceRequestTextColor {
   color: var(--GW-LINK-COLOR);
}

.serviceRequestTextColor phonenumber {
    color: var(--GW-LINK-COLOR);
}

.claimDetailsTable h3 {
    --gw-ve-service-request-claim-details-margin-bottom: 2.5rem;
    margin-bottom: var( --gw-ve-service-request-claim-details-margin-bottom);
}

.tableButtonContainer {
    --gw-ve-service-request-table-button-container-height: 7rem;
    height: var(--gw-ve-service-request-table-button-container-height);
}

.claimDetailsTable div {
    --gw-ve-service-request-claim-details-margin-top: 0.1rem;

    align-items: baseline;
    margin-top: var( --gw-ve-service-request-claim-details-margin-top);
}

.claimDetailsTitle {
    --gw-ve-service-request-claim-details-title-padding-bottom: 1.5rem;
    padding-bottom: var( --gw-ve-service-request-claim-details-title-padding-bottom);
}

.titleContainer {
    display: flex;
    justify-content: space-between;
}

.menuDropdown {
    top: auto;
}
.bottomPanel {
    background:  var(--GW-COLOR-INFO-CONTRAST);
}

.linkTextClassName {
    color: red; 
}

.linkButton {
    height: calc(1.6* var(--GW-SPACING-LG));
}