.titleContainer {
	padding: var(--GW-SPACING-3) 0;
	border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
	display: flex;
	justify-content: space-between;
	width: 100%;
}
.gwRowLayout {
	display: flex;
	align-items: stretch;
	align-content: flex-start;
}
.documentName {
	i {
		--link-icon-font-size: var(--GW-FONT-SIZE-BODY);
	}
	span:hover {
		text-decoration: none;
	}
}
.buttonFileUpload {
	margin-right: var(--GW-SPACING-4);
}
.trashLink {
	i {
		--link-icon-font-size: var(--GW-FONT-SIZE-H5);
	}
}

.documentTitleRight {
	.uploadbutton {
		padding-right: var(--GW-SPACING-5);
		min-width: auto;
	}
}

.noDocumentsText{
    background: transparent;
    color: var(--GW-TEXT-COLOR-1);
    font-size: var(--GW-FONT-SIZE-BODY);
	margin-bottom: var(--GW-SPACING-1);
	font-weight: var(--GW-FONT-WEIGHT-REGULAR);
}
.searchInputSize {
	--gw-search-input-width: 224px;
	width: var(--gw-search-input-width);
}

.gwDocumentContainer {
	padding: var(--GW-SPACING-6) var(--GW-SPACING-6) var(--GW-SPACING-8) var(--GW-SPACING-6);
}
