.gwFaqItemContainer {
  background-color: var(--GW-BACKGROUND-COLOR-BODY);
  margin-top: var(--GW-SPACING-3);
  div[class*='jut__Accordion__accordionFrame'] {
    border: none;
  }
  i {
    line-height: 2;
  }
}

.gwFaqItemLink {
  color: var(--GW-FOCUS-COLOR);
}

.gwFaqText {
  font-weight: var(--GW-FONT-WEIGHT-REGULAR);
}

.gwQuestionTitle {
  --gw-faq-question-title-margin-left: 35px;
  margin-left: var(--gw-faq-question-title-margin-left);
  margin-top: var(--GW-SPACING-2);
}

.gwFaqYouTube {
  --gw-faq-youtube-max-width: 400px;
  --gw-faq-youtube-max-height: 224px;
  --gw-faq-youtube-width: 100%;
  --gw-faq-youtube-height: 50vw;

  max-width: var(--gw-faq-youtube-max-width);
  max-height: var(--gw-faq-youtube-max-height);
  width: var(--gw-faq-youtube-width);
  height: var(--gw-faq-youtube-height);
  margin: auto;
  display: block;
}

.gwHighlightedText {
  background-color: var(--GW-COLOR-WARNING);
}
