@import "~@jutro/theme/assets/sass/helpers";

.page {
  --gw-ve-page-width: 80%;
  --gw-ve-page-margin-bottom: 50px;
  --gw-ve-page-padding-top: 40px;

  @include gw-breakpoint-down(tablet) {
    --gw-ve-page-width: 95%;
  }

  width: var(--gw-ve-page-width);
  margin: auto;
  margin-bottom: var(--gw-ve-page-margin-bottom);
  padding-top: var(--gw-ve-page-padding-top)
}

.veMain {
  background-color: var(--GW-BACKGROUND-COLOR-BODY);
}

// theme
$fonts-root: "../assets/fonts";

// ------------------------------------
//   GROUNDED CSS MODULES
// ------------------------------------
@import "~@jutro/theme/assets/fonts/fonts";
@import "~@jutro/theme/groundedStyles";

// ------------------------------------
//   COMMON CSS MODULES
// ------------------------------------
@import "~@jutro/layout/hashedStyles";
@import "~@jutro/components/hashedStyles";
@import "~@jutro/router/hashedStyles";
@import "~@jutro/floorplan/hashedStyles";
@import "~@jutro/datatable/hashedStyles";
@import "~@jutro/theme/hashedStyles";

.themeRoot {
  --GW-COLOR-BRAND-1-GRADIENT: #007FAE;
}


.jut__FieldComponent__left .jut__FieldLabel__left {
  flex: 1 1 0;
  align-items: flex-start;
  height: auto;
  text-align: left;

  .jut__FieldLabel__fieldLabel {
    margin-right: var(--GW-SPACING-4);
  }
}

.jut__Accordion__accordionToggle {
  height: auto;
}

.jut__ApplicationHeader__subApplicationHeader {
  height: auto;
}

.jut__ModalNext__modal {
  --gw-ve-modal-width: 40rem;
  --gw-modal-max-height: 90vh;

  width: var(--gw-ve-modal-width);
  > * {
    max-height: var(--gw-modal-max-height);
  }
}


.jut__ApplicationHeader__applicationHeader {
  --application-header-logo-size: 40px;
  --application-header-logo-button-size: 150px;
    
}


